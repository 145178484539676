import {
  Button,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Sidepane,
} from '@uss/react-core';
import { useState } from 'react';
import { BsX } from 'react-icons/bs';

interface CopyScheduleProps {
  isOpenDrawer: boolean;
  setOpenCopySchedule: React.Dispatch<React.SetStateAction<boolean>>;
  totalWeeks: number;
  selectedWeek: number;
}

export const CopySchedule = ({
  isOpenDrawer,
  setOpenCopySchedule,
  totalWeeks,
  selectedWeek,
}: CopyScheduleProps) => {
  const weeksArray: number[] = Array.from(
    Array(totalWeeks).keys(),
    (x) => x + 1
  ).filter((n) => n !== selectedWeek);
  const [checked, setChecked] = useState<string[]>([]);
  const handleClose = () => {
    setOpenCopySchedule(false);
  };

  return (
    <>
      <Sidepane
        ariaLabel={''}
        isOpen={isOpenDrawer}
        placement={'right'}
        className="w-full sm:w-3/12 md:w-3/12"
      >
        <Sidepane.Header className="flex">
          <div className="flex text-xl font-semibold flex-grow">
            Copy Schedule
          </div>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </Sidepane.Header>
        <Sidepane.Content className=" overflow-y-auto h-full">
          Select the week/weeks you want to copy this schedule to
          <div className="w-full flex flex-col flex-grow gap-5 mb-6 mt-6">
            <CheckboxGroup label={''} value={checked} onChange={setChecked}>
              <Checkbox> Select All</Checkbox>
              {weeksArray.map((week, index) => (
                <div key={index} className="my-3">
                  <Checkbox value={week.toString()}>{`Week ${week}`}</Checkbox>
                </div>
              ))}
            </CheckboxGroup>
          </div>
        </Sidepane.Content>
        <Sidepane.Controls className="flex md:flex-row flex-col items-end justify-end mt-6">
          <div className="sm:flex sm:justify-between sm:gap-2">
            <Button
              variant="outline"
              color={'primary'}
              full={true}
              type="button"
              onPressChange={() => setOpenCopySchedule(false)}
            >
              Cancel
            </Button>
            <Button variant="filled" color={'primary'} full={true}>
              Copy
            </Button>
          </div>
        </Sidepane.Controls>
      </Sidepane>
    </>
  );
};
