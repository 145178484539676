import { Button, Checkbox, CheckboxGroup } from '@uss/react-core';

export default function DeleteCrewRotations() {
  return (
    <form className="flex flex-col flex-grow ">
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <CheckboxGroup
          label="Select the crew(s) you want to delete"
          orientation="vertical"
        >
          <Checkbox>Select All</Checkbox>
          <Checkbox>Crew A</Checkbox>
          <Checkbox>Crew B</Checkbox>
          <Checkbox>Crew C</Checkbox>
          <Checkbox>Crew D</Checkbox>
        </CheckboxGroup>
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button variant="outline" color={'primary'} full={true} type="button">
          Cancel
        </Button>
        <Button variant="filled" color={'danger'} full={true} type={'submit'}>
          Delete
        </Button>
      </div>
    </form>
  );
}
