import { ROLES } from 'features/roles';
import { MODEL_STATUS, MODEL_TYPE } from '../constants';
import { ModelDetailsResponse } from '../types';

export interface ModelActionButton {
  data: ModelDetailsResponse;
  roles: string[];
  ussId: string;
}

export const getModelActionButton = ({
  data,
  roles,
  ussId,
}: ModelActionButton) => {
  const buttonState = {
    archiveButton: false,
    editButton: false,
    copyButton: false,
    deleteButton: false,
    restoreButton: false,
    moreButton: false
  };
  if (roles.includes(ROLES.PAY_APPROVER)) {
    if (data.modelStatus === MODEL_STATUS.ACTIVE) {
      if (data.owner.ussId.toString() === ussId) {
        buttonState.archiveButton = true;
        buttonState.editButton = true;
        buttonState.copyButton = true;
        return buttonState;
      } else {
        buttonState.copyButton = true;
        return buttonState;
      }
    } else if (data.modelStatus === MODEL_STATUS.PENDING) {
      buttonState.archiveButton = true;
      buttonState.editButton = true;
      buttonState.deleteButton = true;
      return buttonState;
    } else if (data.modelStatus === MODEL_STATUS.ARCHIVED) {
      buttonState.restoreButton = true;
      buttonState.copyButton = true;
      return buttonState;
    } else if (data.modelStatus === MODEL_STATUS.CHECKED_OUT) {
      buttonState.archiveButton = true;
      buttonState.editButton = true;
      return buttonState;
    }
  } else if (
    (roles.includes(ROLES.T_A_PLANT_COORDINATOR) || roles.includes(ROLES.PAYROLL_ADMIN)
      && data.modelType === MODEL_TYPE.PLANT) ||
    roles.includes(ROLES.SUPER_PAYROLL_ADMIN) || roles.includes(ROLES.SUPER_ADMIN)
  ) {
    if (data.modelStatus === MODEL_STATUS.ACTIVE) {
      if (data.modelType === MODEL_TYPE.PLANT) {
        buttonState.moreButton = true;
        buttonState.archiveButton = false;
        buttonState.editButton = true;
        buttonState.copyButton = true;
        return buttonState;
      } else {
        buttonState.archiveButton = true;
        buttonState.editButton = true;
        buttonState.copyButton = true;
        return buttonState;
      }
    } else if (data.modelStatus === MODEL_STATUS.PENDING) {
      buttonState.archiveButton = true;
      buttonState.editButton = true;
      buttonState.deleteButton = true;
      return buttonState;
    } else if (data.modelStatus === MODEL_STATUS.ARCHIVED) {
      buttonState.restoreButton = true;
      buttonState.copyButton = true;
      return buttonState;
    } else if (data.modelStatus === MODEL_STATUS.CHECKED_OUT) {
      buttonState.archiveButton = true;
      buttonState.editButton = true;
      return buttonState;
    }
  }

  return buttonState;
};
