import {
  Button,
  IconButton,
  Label,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
  Sidepane,
  TextAreaField,
  TextField,
} from '@uss/react-core';
import { MODEL_TYPE } from 'features/model-listing/constants';
import { ModelType } from 'features/model-listing/types';
import { getWeekFormatTitle } from 'features/model-listing/utility/getWeekFormatTitle';
import { Key, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Org, ORG_TYPE } from 'features/org';
import { CopyModelSchema } from 'features/model-listing/schemas/copy-model-schema';
import { useEmployeeOrgs } from 'features/org/api';

export interface CopyModelSchema1 {
  modelName: string;
  modelDescription: string;
  modelType: ModelType;
  weekBeginIndex?: number;
  plantOrgId?: string;
  locationOrgId?: string;
}
interface CopyModelProps {
  isOpenDrawer: boolean;
  setOpenCopyModel: React.Dispatch<React.SetStateAction<boolean>>;
  modelType: ModelType;
}

export const CopyModel = ({
  isOpenDrawer,
  setOpenCopyModel,
  modelType,
}: CopyModelProps) => {
  const [plant, setPlant] = useState<Org | null>(null);
  const [plantOrgId, setPlantOrgId] = useState<string | null>(null);
  const [localtionOrgId, setLocaltionOrgId] = useState<string | null>(null);
  const defaultValues = {
    modelName: '',
    modelDescription: '',
    modelType: modelType,
    weekBeginIndex: 0,
    plantOrgId: '',
    locationOrgId: '',
  };
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CopyModelSchema1>({
    mode: 'onSubmit',
    resolver: zodResolver(CopyModelSchema),
    defaultValues: defaultValues,
  });

  const plantId = useWatch({ control, name: 'plantOrgId' });
  //plant selection
  const { data: plantList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
  });

  //location selection
  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId: !plantId ? undefined : plantId,
  });
  //setting weekBeginIndex to 0 as default value
  useEffect(() => {
    setValue('weekBeginIndex', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePlantSelect = (val: Key) => {
    setPlant(plantList?.items.find((e) => e.id === val) ?? null);
    setPlantOrgId(val.toString());
    //updating form states
    setValue('plantOrgId', val.toString());
    //setting payrollLocation to 'All' as default option
    setValue('locationOrgId', '0');
    setLocaltionOrgId('0');
  };

  const handlePayrollSelect = (val: Key) => {
    setLocaltionOrgId(val.toString());
    setValue('locationOrgId', val.toString());
  };
  const handleWeekFormat = (e: Key) => {
    setValue('weekBeginIndex', Number(e));
  };

  const handleModelType = (val: Key) => {
    let modelVal: ModelType = 'global';
    if (val === 'plant') modelVal = 'plant';
    setValue('modelType', modelVal);
    //resetting plant and location values on modelType change
    if (val === 'global') {
      setValue('plantOrgId', '');
      setValue('locationOrgId', '');
      setPlantOrgId(null);
      setLocaltionOrgId(null);
    }
  };
  const handleModelCopy = (data: CopyModelSchema1) => {
    setOpenCopyModel(true);
  };
  const handleClose = () => {
    setOpenCopyModel(false);
  };

  return (
    <>
      <Sidepane
        ariaLabel={''}
        isOpen={isOpenDrawer}
        className="w-full sm:w-3/12 md:w-3/12"
      >
        <Sidepane.Header className="flex">
          <div className="text-xl font-semibold flex-grow">Copy Model</div>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </Sidepane.Header>

        <form
          className="flex flex-col flex-grow"
          onSubmit={handleSubmit(() => handleModelCopy(getValues()))}
        >
          <div className=" text-sm font-normal mt-4 mb-3">
            Copy Existing Model To New Model Name.
          </div>
          <div className="w-full flex flex-col flex-grow gap-3 ">
            <Controller
              control={control}
              name="modelName"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <Label>
                    <p className="text-xs text-black">New Model Name</p>
                  </Label>
                  <TextField
                    type="text"
                    label=""
                    placeholder="Enter new model name"
                    errorMessage={errors.modelName?.message}
                    onChange={field.onChange}
                    maxLength={10}
                    isInvalid={invalid}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="modelType"
              render={({ field }) => (
                <RadioGroup
                  label={'Model Type'}
                  value={field.value}
                  orientation={'horizontal'}
                  onChange={handleModelType}
                  isDisabled={true}
                >
                  <Radio value={'global'} isDisabled={true}>
                    Global
                  </Radio>
                  <Radio value={'plant'} isDisabled={true}>
                    Plant
                  </Radio>
                </RadioGroup>
              )}
            />

            {modelType === MODEL_TYPE.GLOBAL && (
              <Controller
                control={control}
                name="weekBeginIndex"
                render={({ field }) => (
                  <RadioGroup
                    label={'Week Format'}
                    value={field.value ? field.value.toString() : '0'}
                    orientation={'horizontal'}
                    onChange={handleWeekFormat}
                  >
                    <Radio value={'0'}>Sun-Sat</Radio>
                    <Radio value={'6'}>Sat-Fri</Radio>
                  </RadioGroup>
                )}
              />
            )}
            {modelType === MODEL_TYPE.PLANT && (
              <>
                <Controller
                  control={control}
                  name="plantOrgId"
                  render={({ field, fieldState: { invalid, error } }) => (
                    <div>
                      <Select
                        items={plantList?.items}
                        selectedKey={plantOrgId}
                        onSelectionChange={handlePlantSelect}
                        label="Plant"
                        className="w-full"
                        isInvalid={plantOrgId === null}
                        errorMessage={errors.plantOrgId?.message}
                      >
                        {(item) => (
                          <SelectOptions
                            id={item.id}
                          >{`${item.plant}`}</SelectOptions>
                        )}
                      </Select>
                      {plant && (
                        <div className="flex flex-row mt-2 items-center">
                          <BsExclamationCircle
                            size={'1rem'}
                            className="mr-2 text-3xl"
                          />
                          <p className="text-xs">
                            Week Format:{' '}
                            {getWeekFormatTitle(plant.weekBeginDayIndex)}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="locationOrgId"
                  render={({ field, fieldState: { invalid, error } }) => (
                    <div>
                      <Select
                        items={locationList?.items}
                        selectedKey={localtionOrgId}
                        onSelectionChange={handlePayrollSelect}
                        label="Payroll location"
                        isDisabled={
                          locationStatus === 'pending' || plantOrgId === null
                        }
                        className="w-full"
                        isInvalid={localtionOrgId === null}
                        errorMessage={errors.locationOrgId?.message}
                      >
                        {(item) => (
                          <>
                            <SelectOptions id={'0'}>All</SelectOptions>
                            <SelectOptions id={item.id}>
                              {item.location &&
                                `${item.location} - ${
                                  item.locationDescription ?? ''
                                }`}
                            </SelectOptions>
                          </>
                        )}
                      </Select>
                    </div>
                  )}
                />
              </>
            )}
            <div>
              <Label>
                <p className="text-xs text-black">Model Description</p>
              </Label>
              <Controller
                control={control}
                name="modelDescription"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextAreaField
                    maxLength={100}
                    label=""
                    placeholder="Enter model description"
                    onChange={field.onChange}
                    isInvalid={invalid}
                    errorMessage={errors.modelDescription?.message}
                  />
                )}
              />
            </div>
          </div>
          <Sidepane.Controls className="flex md:flex-row flex-col items-end justify-end mt-6">
            <div className="sm:flex sm:justify-between sm:gap-2">
              <Button
                variant="outline"
                color={'primary'}
                full={true}
                type="button"
                onPressChange={() => setOpenCopyModel(false)}
              >
                Cancel
              </Button>
              <Button variant="filled" color={'primary'} full={true}>
                Copy
              </Button>
            </div>
          </Sidepane.Controls>
        </form>
      </Sidepane>
    </>
  );
};
