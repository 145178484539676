import { NumberField } from '@uss/react-core';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { ModelSchedTurns } from 'features/model-listing/types';
import { useState } from 'react';
interface ScheduleTurnInputProps {
  schedTurn?: ModelSchedTurns;
  weekNo: number;
  crewRotation: string;
}
export const ScheduleTurnInput = (data: ScheduleTurnInputProps) => {
  const [value, setValue] = useState<number | undefined>(
    data.schedTurn?.turnValue
  );
  const store = useEditModelStore();
  const handleChange = (e: number) => {
    setValue(e);
    const updateSchedTurn = store.weeks?.map((s) =>
      s.weekNo !== data.weekNo
        ? s
        : {
            weekNo: s.weekNo,
            crewRotations: s.crewRotations.map((crew) =>
              crew.crewRotation !== data.crewRotation
                ? crew
                : {
                    crewRotation: crew.crewRotation,
                    schedTurns: crew.schedTurns.map((turn) =>
                      turn.pkScheduleTurnId !== data.schedTurn?.pkScheduleTurnId
                        ? turn
                        : { ...turn, turnValue: e }
                    ),
                  }
            ),
          }
    );
    store.updateWeeks(updateSchedTurn ? updateSchedTurn : store.weeks);
  };
  return (
    <>
      <NumberField
        id={data.schedTurn?.pkScheduleTurnId}
        aria-label={value?.toString()}
        value={value === 0 ? NaN : value}
        onChange={(e) => handleChange(e)}
        className="[&_input]:p-0 [&_input]:flex [&_input]:text-center"
      />
    </>
  );
};
