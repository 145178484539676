import { useQuery } from '@tanstack/react-query';
import { ModelDetailParams, ModelListParams } from '../types';
import { modelDetails, modelList } from './api';

export const useModelList = (ussId: string, options?: ModelListParams) => {
  return useQuery({
    queryFn: () => modelList.get(options),
    queryKey: ['model-list', { ussId, ...options }],
  });
};

export const useModelDetails = (
  modelId: string,
  options: ModelDetailParams
) => {
  return useQuery({
    queryFn: () => modelDetails.get(modelId, options),
    queryKey: ['model-details', { modelId, options }],
  });
};
