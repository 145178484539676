import { Button, IconButton, Modal, Sidepane } from '@uss/react-core';
import { BsX } from 'react-icons/bs';
import { useState } from 'react';
import ApplyModelForm from '../Forms/ApplyModelForm';
import { NavLink } from 'react-router-dom';
import { EmployeeTimecard } from 'features/timecards/types';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';

interface ApplyModelDrawerType {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  employeeTimecard?: EmployeeTimecard[];
  shiftAssignment?: string;
}

export default function ApplyModelDrawer({
  openDrawer,
  setOpenDrawer, employeeTimecard, shiftAssignment
}: ApplyModelDrawerType) {
  const [isOpen, setIsOpen] = useState(false);
  const state = useNonUnionStore();
  const feature = state.feature;
  const isSchedulingDetails = feature === 'scheduling';

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const getModelText = () => {
    if (isSchedulingDetails) {
      return 'Choose the model you want to apply to all the employees selected'
    } else return 'Select the model you want to apply'
  }

  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
        className={'p-2'}
      >
        <div className="w-full flex gap-10 items-center mb-2">
          <h3 className="text-lg flex-grow">Apply Model</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        <p className="text-sm mb-1 text-wrap">{getModelText()}</p>
        <NavLink
          className={
            'text-primary-400 font-semibold cursor-pointer text-sm'
          }
          to='/non-union-scheduling/modeling'
        >
          View Model
        </NavLink>
        {isSchedulingDetails && <p className='text-sm mt-2'>Crew: ABCD</p>}
        <ApplyModelForm
          defaultValues={{
            model: '',
            crewRotations: '',
            numOfHours: '',
            startWeek: 0,
            endWeek: 0,
          }}
          handleClose={handleClose}
          employeeTimecard={employeeTimecard}
          shiftAssignment={shiftAssignment}
          isSchedulingDetails={isSchedulingDetails}
        />
      </Sidepane>
      {isOpen && (
        <Modal ariaLabel="" size="default" isOpen={isOpen}>
          <Modal.Header>Create Model</Modal.Header>
          <Modal.Content>
            <p className="text-sm text-gray pb-8">
              Applying the model will override existing schedule(s) for weeks Apr 10, 2021 - May 8, 2021. Are you sure you want to apply this model?
            </p>
          </Modal.Content>
          <Modal.Footer>
            <div className="sm:flex sm:justify-between sm:gap-2">
              <Button
                variant="outline"
                color={'primary'}
                full={false}
                type="button"
                onPressChange={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color={'primary'}
                full={false}
                type={'submit'}
              //onPressChange={fnCreateModel}
              >
                Apply
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
