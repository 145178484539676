import { IconButton, Sidepane, Tab } from '@uss/react-core';
import { BsX } from 'react-icons/bs';

import { AddWeeks } from './AddWeeks';
import { AddCrewRotations } from './AddCrewRotations';
import { ModelDetailsResponse } from 'features/model-listing/types';

interface DeleteWeeksCrewsDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  data: ModelDetailsResponse | undefined;
}
export const AddWeeksCrewsRotationsDrawer = ({
  openDrawer,
  setOpenDrawer,
  data,
}: DeleteWeeksCrewsDrawerProps) => {
  const handleClose = () => {
    setOpenDrawer(false);
  };
  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
        className="w-full sm:w-1/2 md:w-2/5 lg:w-[30%]"
      >
        <div className="w-full flex gap-10 items-center pb-7">
          <h3 className="text-lg flex-grow">Add Week(s) / Crew(s)</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        <Tab
          orientation="horizontal"
          selectedKey={'2'}
          equalWidthTabs
          tabLinks={[
            {
              id: 'AddWeeks',
              title: 'Week',
              content: <AddWeeks data={data} />,
            },
            {
              id: 'AddCrewRotations',
              title: 'Crew Rotation',
              content: <AddCrewRotations />,
            },
          ]}
        />
      </Sidepane>
    </>
  );
};
