import {
  Button,
  MenuTrigger,
  Modal,
  Popover,
  UnstyledButton,
} from '@uss/react-core';
import { MODEL_STATUS } from 'features/model-listing/constants';
import { ModelDetailsResponse } from 'features/model-listing/types';
import { getModelActionButton } from 'features/model-listing/utility/getModelActionButton';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { WeekCard } from './WeekCard';
import Pagination from 'components/Pagination/Pagination';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { useNavigate } from 'react-router-dom';
import { useEditModel } from 'features/model-listing/api/mutations';
import { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { BiChevronUp } from 'react-icons/bi';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';
import { CopyModel } from './CopyModel';
export interface ModelDetailProps {
  data: ModelDetailsResponse;
}
export const ModelDetailTab = ({ data }: ModelDetailProps) => {
  const { roles, uss_id } = useUserRoles();
  const store = useModelDetailStore();
  const [openCopyModel, setOpenCopyModel] = useState<boolean>(false);

  const { mutateAsync } = useEditModel(data.id);
  const navigate = useNavigate();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isArchiveBtnOpen, setIsArchiveBtnOpen] = useState<boolean>(false);
  const {
    archiveButton,
    editButton,
    copyButton,
    deleteButton,
    restoreButton,
    moreButton,
  } = getModelActionButton({
    data,
    roles,
    ussId: uss_id,
  });
  const isDisabled = () => {
    return roles.includes(ROLES.PAY_APPROVER) &&
      data.owner.ussId.toString() !== uss_id &&
      data.modelStatus !== MODEL_STATUS.ACTIVE
      ? true
      : false;
  };

  const handleEditClick = async () => {
    //enabling edit for Active-models only
    if (data.modelStatus === MODEL_STATUS.ACTIVE) {
      await mutateAsync(
        {
          action: 'checkout',
        },
        {
          onSuccess: () => {
            navigate('edit-model');
          },
        }
      );
    } else {
      navigate('edit-model');
    }
  };

  const handleArchive = async () => {
    await mutateAsync(
      {
        action: 'archive',
      },
      {
        onSuccess: async () => {
          setIsArchiveModalOpen(false);
          notify.success({
            message: 'You have successfully archived this model.',
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      }
    );
  };

  const handleArchiveMoreClose = () => {
    setIsArchiveBtnOpen(false);
    return false;
  };

  return (
    <>
      <div className=" grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-4 gap-4 mb-4">
        {data.weeks.map((week, index) => {
          return (
            <WeekCard
              weekData={week}
              modelName={data.modelName}
              weekBeginIndex={data.weekBeginIndex}
              totalWeeks={data.totalWeeks}
              key={index}
            />
          );
        })}
      </div>
      <Pagination
        pageOptions={[9, 18, 27, 36, 45]}
        totalCount={data.totalWeeks}
        page={data.page}
        pageSize={data.pageSize}
        onPageChange={(s) => store.setPage(s.selected + 1)}
        onPageSizeChange={(ps) => store.setPageSize(ps)}
      />

      <div className="flex justify-end flex-wrap gap-2 mt-5">
        {moreButton && (
          <MenuTrigger isOpen={isArchiveBtnOpen}>
            <Button
              variant={'outline'}
              onPress={() => setIsArchiveBtnOpen(!isArchiveBtnOpen)}
            >
              More <BiChevronUp size="1.25rem" className="ml-2" />
            </Button>
            <Popover
              placement="top"
              isOpen={isArchiveBtnOpen}
              shouldCloseOnInteractOutside={handleArchiveMoreClose}
            >
              <div className="flex flex-col">
                <Button
                  variant={'subtle'}
                  onPress={() => {
                    setIsArchiveModalOpen(true);
                    setIsArchiveBtnOpen(false);
                  }}
                >
                  Archive
                </Button>
                <Button
                  variant={'subtle'}
                  onPress={() => {
                    setIsArchiveBtnOpen(true);
                  }}
                >
                  Transfer Ownership
                </Button>
              </div>
            </Popover>
          </MenuTrigger>
        )}
        {archiveButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
            onPress={() => setIsArchiveModalOpen(true)}
          >
            Archive
          </Button>
        )}

        {deleteButton && (
          <Button
            variant="outline"
            color="danger"
            type="button"
            isDisabled={isDisabled()}
          >
            Delete
          </Button>
        )}
        {restoreButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
          >
            Restore
          </Button>
        )}

        {copyButton && (
          <Button
            variant="filled"
            color={'primary'}
            type="button"
            isDisabled={isDisabled()}
            onPress={() => setOpenCopyModel(true)}
          >
            Copy
          </Button>
        )}
        <CopyModel
          isOpenDrawer={openCopyModel}
          setOpenCopyModel={setOpenCopyModel}
          modelType={data.modelType}
        />
        {editButton && (
          <>
            <Button
              variant={'filled'}
              color={'primary'}
              type="button"
              className="flex justify-center items-center relative rounded outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-secondary-500 text-white min-w-[128px] px-[15px] disabled:bg-gray-300 disabled:text-gray-500 text-base py-[9px] leading-4 bg-brand border hover:bg-brand-hover hover:border-brand-hover disabled:border-gray-300"
              onPress={handleEditClick}
            >
              Edit
            </Button>
          </>
        )}

        <Modal
          ariaLabel={'archive-modal'}
          isDismissable
          isOpen={isArchiveModalOpen}
          size="default"
        >
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <h2>Archive the Model?</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsArchiveModalOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            <div>Are you sure you want to archive this model?</div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-2">
              <Button
                variant={'outline'}
                onPress={() => setIsArchiveModalOpen(false)}
              >
                Cancel
              </Button>
              <Button variant={'filled'} onPress={handleArchive}>
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
