import { useMutation } from "@tanstack/react-query";
import { modelEdit, modelList } from "./api";
import { notify } from "components/Notification/useNotification";
import { CreateModel } from "../components/Forms/CreateModelForm";
import { useNavigate } from "react-router-dom";
import { CreateModelResponse, CheckoutModelReqBody } from "../types";

export const useCreateModel = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (body: CreateModel) => modelList.post(body),
    onSuccess: (response:CreateModelResponse) => {
      navigate(`/non-union-scheduling/modeling/${response.id}/edit-model`);
      notify.success({
        message: `You have successfully created a new model.`,
      });
    },
  });
};

export const useEditModel = (id:string) => {
  return useMutation({
    mutationFn: (body: CheckoutModelReqBody) => modelEdit.post(body,id)
  });
};