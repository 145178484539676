import { IconButton, Sidepane, Tab } from '@uss/react-core';
import { BsX } from 'react-icons/bs';
import DeleteWeeks from './DeleteWeeks';
import DeleteCrewRotations from './DeleteCrewRotations';

interface DeleteWeeksCrewsDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
}

export default function DeleteWeeksCrewsRotationsDrawer({
  openDrawer,
  setOpenDrawer,
}: DeleteWeeksCrewsDrawerProps) {
  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Sidepane
        ariaLabel="filter"
        placement={'right'}
        isOpen={openDrawer}
        onOpenChange={handleClose}
        className="w-full sm:w-2/5 lg:w-1/4"
      >
        <div className="w-full flex gap-10 items-center pb-7">
          <h3 className="text-lg flex-grow">Delete Week(s) / Crew(s)</h3>
          <IconButton onPress={handleClose}>
            <BsX className="text-gray-600" />
          </IconButton>
        </div>
        <Tab
          orientation="horizontal"
          selectedKey={'2'}
          equalWidthTabs
          tabLinks={[
            {
              id: 'DeleteWeeks',
              title: 'Week',
              content: <DeleteWeeks />,
            },
            {
              id: 'DeleteCrewRotations',
              title: 'Crew Rotation',
              content: <DeleteCrewRotations />,
            },
          ]}
        />
      </Sidepane>
    </>
  );
}
