import { axios } from 'services/api/axios';
import {
  CheckoutModelReqBody,
  CheckoutModelResponseBody,
  CreateModelResponse,
  ModelDetailParams,
  ModelDetailsResponse,
  ModelListParams,
  ModelListResponse,
} from '../types';
import { CreateModel } from '../components/Forms/CreateModelForm';

export const modelList = {
  get: (options?: ModelListParams): Promise<ModelListResponse | undefined> => {
    return axios.get(`/v1/timecard-model`, {
      params: options,
    });
  },
  post: (body: CreateModel): Promise<CreateModelResponse> => {
    return axios.post(`/v1/timecard-model`, body);
  },
};

export const modelDetails = {
  get: (
    modelId: string,
    options: ModelDetailParams
  ): Promise<ModelDetailsResponse> => {
    return axios.get(`/v1/timecard-model/${modelId}`, { params: options });
  },
};

export const modelEdit = {
  post: (body: CheckoutModelReqBody, id:string): Promise<CheckoutModelResponseBody> => {
    return axios.post(`/v1/timecard-model/${id}/edit`, body);
  },
};
