import { ModelCrewRotation, ModelSchedTurns, ModelWeeks } from '../types';

export const getEmptyWeekObject = (totalCrewRotations: number) => {
  const crewRotationItems = ['A', 'B', 'C', 'D'];

  const schedTurns: ModelSchedTurns[] = [
    {
      pkScheduleTurnId: '',
      dayIndex: 0,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 1,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 2,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 3,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 4,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 5,
      turnValue: 0,
    },
    {
      pkScheduleTurnId: '',
      dayIndex: 6,
      turnValue: 0,
    },
  ];
  let crewRotations: ModelCrewRotation[] = [];
  for (let i = 0; i < totalCrewRotations; i++) {
    crewRotations = [
      ...crewRotations,
      { crewRotation: crewRotationItems.at(i) ?? '', schedTurns: schedTurns },
    ];
  }
  const newWeek: ModelWeeks = {
    weekNo: 53,
    crewRotations: crewRotations,
  };
  return newWeek;
};
