import { z } from "zod";

export const ApplyModelSchema = z.object({
  model: z.string().nonempty(),
  crewRotations: z.string().nonempty(),
  numOfHours: z.coerce.number()
    .int()
    .gt(0.1)
  .lte(24).optional(),
  startWeek: z.coerce.number({
    required_error : 'Start week is required'
  }),
  endWeek: z.coerce.number({
    required_error : 'End week is required'
  }),
  selectedHrsOption: z.union([z.literal('default'), z.literal('custom')]).default('default').optional(),
})
.refine(
  (val) => {
      if (val.endWeek < val.startWeek) return false;
    return true;
    },
    {
      message: 'Start Week should never be equal or greater than the End Week.',
      path: ['startWeek'],
    }
)
 