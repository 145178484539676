import Page from 'components/Layouts/Page';
import { useModelDetails } from 'features/model-listing/api/queries';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Detailscard } from 'features/model-listing/components/ModelDetails/ModelDetailsCard';
import { EditModelDetails } from 'features/model-listing/components/ModelEdit/EditModelDetails';
import { Alert } from '@uss/react-core';
import { Button } from '@uss/react-components';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { WeekCardLoader } from 'features/model-listing/components/ModelDetails/WeekCardLoader';
import CardsLoader from 'components/Loaders/CardsLoader';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { MODEL_STATUS } from 'features/model-listing/constants';
import { useState } from 'react';
import DeleteWeeksCrewsRotationsDrawer from '../../../features/model-listing/components/ModelEdit/DeleteWeeksCrewRotations/DeleteWeeksCrewRotationsDrawer';
import { useEffect } from 'react';
import { AddWeeksCrewsRotationsDrawer } from 'features/model-listing/components/AddWeeksCrewDrawer/AddWeeksCrewRotationsDrawer';

export const EditModel = () => {
  const { id = '' } = useParams<'id'>();
  const store = useEditModelStore();

  const { data, status } = useModelDetails(id, store.params);

  //Delete Weeks/Crew Rotations Drawer
  const [openDeleteWeeksCrewDrawer, setOpenDeleteWeeksCrewDrawer] =
    useState(false);
  const { data: modelDetail } = useModelDetails(id, {
    page: 1,
    pageSize: 52,
  });
  useEffect(() => {
    modelDetail && store.updateWeeks(modelDetail.weeks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelDetail]);
  const [openAddWeeksCrewDrawer, setOpenAddWeeksCrewDrawer] =
    useState<boolean>(false);
  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="bc-1" to="/scheduling">
            Scheduling
          </NavLink>,
          <NavLink key="bc-2" to="/non-union-scheduling/modeling">
            Modeling
          </NavLink>,
        ]}
        heading={'Edit Model Details'}
      >
        <Page.Section>
          <div className="flex flex-row-reverse gap-5">
            <div className="flex flex-nowrap gap-4">
              <Button
                variant="text"
                className="text-sm  text-red-600 cursor-pointer"
                onClick={() => setOpenDeleteWeeksCrewDrawer(true)}
              >
                <BsTrash id="delete" className="text-sm" />
                <span className=" ml-2">Week(s)/Crew(s)</span>
              </Button>
              <Button
                variant="text"
                className="text-sm text-primary-400 cursor-pointer"
                onClick={() => setOpenAddWeeksCrewDrawer(true)}
              >
                <BsPlus size={'1.5rem'} className="text-sm" />
                <span>Week(s)/Crew(s)</span>
              </Button>
            </div>
            <AddWeeksCrewsRotationsDrawer
              openDrawer={openAddWeeksCrewDrawer}
              setOpenDrawer={setOpenAddWeeksCrewDrawer}
              data={data}
            />
            {data?.modelStatus === MODEL_STATUS.CHECKED_OUT && (
              <div className="flex-grow">
                <Alert
                  variant="info"
                  message="You have checked out this model. Click on Save & Publish to check the model back in to Active status."
                ></Alert>
              </div>
            )}
          </div>
        </Page.Section>
        <Page.Section>
          {status === 'success' ? (
            <Detailscard data={data} />
          ) : (
            <CardsLoader variant="row-grid" count={1} hasAvatar />
          )}
        </Page.Section>
        <Page.Section>
          {status === 'success' ? (
            <EditModelDetails data={data} />
          ) : (
            <WeekCardLoader
              numberOfWeeks={6}
              numberOfCrewRotations={3}
              numberOfGridCols={2}
            />
          )}
          <DeleteWeeksCrewsRotationsDrawer
            openDrawer={openDeleteWeeksCrewDrawer}
            setOpenDrawer={setOpenDeleteWeeksCrewDrawer}
          />
        </Page.Section>
      </Page>
    </>
  );
};
