import { useState } from 'react';
import {
  Button,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { crewRotationItems } from 'features/model-listing/constants';

export const AddCrewRotations = () => {
  const [value, setValue] = useState();
  const store = useModelDetailStore();
  const numberOfCrewRotationsAdded = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className=" overflow-y-auto h-[calc(100vh-14rem)]">
          <div className="text-xs mb-3">
            Add Crew Rotation
            <RadioGroup value={value} orientation="horizontal">
              <Radio value={'Before'}> Before</Radio>
              <Radio value={'After'}> After</Radio>
            </RadioGroup>
          </div>

          <Select
            items={crewRotationItems.slice(
              0,
              store.modelDetail?.totalCrewRotations
            )}
            isDisabled={false}
            className="w-full"
          >
            {(item) => <SelectOptions id={item.id}>{item.value}</SelectOptions>}
          </Select>

          <Select
            items={numberOfCrewRotationsAdded}
            // selectedKey={}
            // onSelectionChange={}
            label="Select the number of crew rotations to be added"
            isDisabled={false}
            className="w-full mt-3"
          >
            {(item) => <SelectOptions id={item.id}>{item.id}</SelectOptions>}
          </Select>
        </div>
        <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
          <Button variant="outline" color={'primary'} full={true} type="button">
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'submit'}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};
