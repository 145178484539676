import { Button } from '@uss/react-core';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { ModelWeeks } from 'features/model-listing/types';
import { getDaysFormat } from 'features/model-listing/utility/getDaysFormat';
import { getWeekFormat } from 'features/model-listing/utility/getWeekFormat';
import { useEffect, useState } from 'react';
import { BsFiles, BsTrash } from 'react-icons/bs';
import { CopySchedule } from './CopySchedule';
import { ScheduleTurnInput } from './ScheduleTurnInput';

export interface calenderViewProps {
  weekData: ModelWeeks;
  weekBeginIndex: number;
  modelName: string;
  totalWeeks: number;
}
export const ModelWeekEditCard = ({
  weekData,
  modelName,
  weekBeginIndex,
  totalWeeks,
}: calenderViewProps) => {
  const store = useEditModelStore();
  const [openCopySchedule, setOpenCopySchedule] = useState<boolean>(false);
  useEffect(() => {
    store.updateEachWeek(weekData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekData]);
  return (
    <>
      <div className="flex flex-col bg-white max-h-max w-full shadow-md rounded-md py-4 px-6 ">
        <div className="border-b-2 border-gray-300  pb-3 ">
          <div className=" flex flex-row">
            <div className=" w-full font-semibold text-lg">{`Week ${weekData.weekNo}`}</div>
          </div>
        </div>
        <div className="py-3">
          <div className="w-full grid gap-4 grid-cols-8 items-center">
            <div className=" text-base text-center"></div>
            {getDaysFormat(weekBeginIndex).map((day, _index) => (
              <div
                key={_index}
                className="text-center font-semibold text-base justify-center"
              >
                {day?.value.charAt(0)}
              </div>
            ))}

            {weekData.crewRotations.map((crewRotation, index) => (
              <>
                <div
                  key={index}
                  className="font-semibold text-base text-center"
                >
                  {crewRotation.crewRotation}
                </div>

                {getWeekFormat({
                  data: [...crewRotation.schedTurns],
                  weekBeginIndex: weekBeginIndex,
                }).map((schedTurn, _index) => (
                  <div key={_index} className="  text-center text-sm ">
                    <ScheduleTurnInput
                      schedTurn={schedTurn}
                      weekNo={weekData.weekNo}
                      crewRotation={crewRotation.crewRotation}
                    />
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>

        <div className="flex w-full border-t-2 border-gray-300 pt-3">
          <div className="flex w-1/2 justify-center">
            <Button
              variant="text"
              className="text-sm text-primary-400 cursor-pointer"
              onPressChange={() => setOpenCopySchedule(true)}
            >
              <BsFiles className="text-sm" />
              <span className="text-xs ml-2"> Copy Schedule</span>
            </Button>
            <CopySchedule
              isOpenDrawer={openCopySchedule}
              setOpenCopySchedule={setOpenCopySchedule}
              totalWeeks={totalWeeks}
              selectedWeek={weekData.weekNo}
            />
          </div>
          <div className="bg-gray-300 w-0.5 mx-6"></div>
          <div className="flex w-1/2  justify-center">
            <Button
              variant="text"
              className="text-sm  text-red-600 cursor-pointer"
            >
              <BsTrash id="delete" className="text-sm" />
              <span className="text-xs ml-2"> Delete</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
