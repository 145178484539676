import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Label,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
  TextField,
} from '@uss/react-core';
import { format, parseISO } from 'date-fns';
import { ApplyModelSchema } from 'features/model-listing/schemas/apply-model-schema';
import { EmployeeTimecard } from 'features/timecards/types';
import { Key, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

interface Dropdown<T> {
  id: T,
  value: T;
}

export interface ApplyModel {
  model: string
  crewRotations: string
  numOfHours: string;
  startWeek: number;
  endWeek: number;
  selectedHrsOption?: string;
}

interface ApplyModelProps {
  defaultValues: ApplyModel;
  handleClose: () => void;
  employeeTimecard: EmployeeTimecard[] | undefined;
  shiftAssignment: string | undefined;
  isSchedulingDetails: boolean;
}

export default function ApplyModelForm({
  defaultValues, handleClose, employeeTimecard, shiftAssignment, isSchedulingDetails
}: ApplyModelProps) {

  const [disableButton, setDisableButton] = useState(false);

  const modelNameList: Dropdown<string>[] = [
    { id: 'AB', value: 'AB' },
    { id: 'BB', value: 'BB' },
    { id: 'CD', value: 'CD' },
    { id: 'DD', value: 'DD' },
  ];
  const crewRotationItems: Dropdown<string>[] = [
    { id: 'A', value: 'A' },
    { id: 'B', value: 'B' },
    { id: 'C', value: 'C' },
    { id: 'D', value: 'D' },
  ];
  const startWeekItems: Dropdown<number>[] = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
  ];
  const endWeekItems: Dropdown<number>[] = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },

  ];

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyModel>({
    mode: 'onSubmit',
    resolver: zodResolver(ApplyModelSchema),
    defaultValues: defaultValues,
  });

  //watch states
  const model = useWatch({ control, name: 'model' });
  const crewRotations = useWatch({ control, name: 'crewRotations' });
  const numOfHours = useWatch({ control, name: 'numOfHours' });
  const startWeek = useWatch({ control, name: 'startWeek' });
  const endWeek = useWatch({ control, name: 'endWeek' });
  const selectedHrsOption = useWatch({ control, name: 'selectedHrsOption' })

  //to disable button if fields are empty
  useEffect(() => {
    if (!model || !crewRotations ||
      !startWeek || !endWeek || (!isSchedulingDetails && !numOfHours) || (isSchedulingDetails && selectedHrsOption === 'custom' && !numOfHours)) {
      setDisableButton(true)
    } else setDisableButton(false)
  }, [model, crewRotations, numOfHours, startWeek, endWeek, isSchedulingDetails, selectedHrsOption]);

  useEffect(() => {
    if (isSchedulingDetails) {
      setValue('selectedHrsOption', 'default')
    }
  }, [isSchedulingDetails, setValue]);

  const handleNumOfWeeks = (e: Key) => {
    setValue('selectedHrsOption', String(e))
  };

  const handleApplyModel = () => {
    //console.log("Apply>>>>", getValues())
  }

  const getNumOfHrField = () => {
    return <Controller
      control={control}
      name="numOfHours"
      render={({ field, fieldState: { invalid, error } }) => (
        <div>
          <Label>
            <p className="text-xs text-black">Number of Hours</p>
          </Label>
          <TextField
            label=""
            type="number"
            defaultValue={numOfHours}
            placeholder="Enter number of weeks"
            onChange={field.onChange}
            isInvalid={invalid}
            errorMessage={errors.numOfHours?.message}
            aria-label={'Number of weeks'}
          />
        </div>
      )}
    />
  }

  return (
    <form
      className="flex flex-col flex-grow mt-4"
      onSubmit={handleSubmit(() => handleApplyModel())}
    >
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <Controller
          control={control}
          name="model"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={modelNameList}
                selectedKey={model}
                onSelectionChange={field.onChange}
                label="Model"
                className="w-full"
                errorMessage={errors.model?.message}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name="crewRotations"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={crewRotationItems}
                selectedKey={crewRotations}
                onSelectionChange={field.onChange}
                label="Crew Rotations"
                className="w-full"
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        {!isSchedulingDetails && getNumOfHrField()}
        <Controller
          control={control}
          name="startWeek"
          render={({ field, fieldState: { invalid, error } }) => (

            <div>
              <Select
                items={startWeekItems}
                selectedKey={startWeek}
                onSelectionChange={field.onChange}
                label="Start Week"
                className="w-full"
                isInvalid={invalid}
                errorMessage={errors.startWeek?.message}
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
              {employeeTimecard && <p className='text-xs'>Start Week Begining date
                {' '}{format(parseISO(employeeTimecard[0]?.week), 'PP')} </p>}
            </div>

          )}
        />
        <Controller
          control={control}
          name="endWeek"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={endWeekItems}
                selectedKey={endWeek}
                onSelectionChange={field.onChange}
                label="End Week"
                className="w-full"
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        {isSchedulingDetails &&
          <>
            <Controller
              control={control}
              name="selectedHrsOption"
              render={({ field }) => (
                <RadioGroup
                  label={'Number of Hours'}
                  value={field.value}
                  orientation={'vertical'}
                  onChange={handleNumOfWeeks}
                >
                  <Radio value={'default'} >
                    Default to shift assignment
                  </Radio>
                  <Radio value={'custom'}>Custom</Radio>
                </RadioGroup>
              )}
            />
            {selectedHrsOption === 'custom' && getNumOfHrField()}
          </>
        }
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button
          variant="outline"
          color={'primary'}
          full={true}
          type="button"
          onPressChange={handleClose}
        >
          Cancel
        </Button>
        <Button variant="filled" color={'primary'} full={true} type={'submit'}
          isDisabled={disableButton}>
          Apply
        </Button>
      </div>
    </form>
  );
}
