import { Button, Checkbox, CheckboxGroup } from '@uss/react-core';

export default function DeleteWeeks() {
  return (
    <form className="flex flex-col flex-grow">
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <CheckboxGroup
          orientation="vertical"
          label="Select the week(s) you want to delete"
        >
          <Checkbox>Select All</Checkbox>
          <Checkbox>Week 1</Checkbox>
          <Checkbox>Week 2</Checkbox>
          <Checkbox>Week 3</Checkbox>
          <Checkbox>Week 4</Checkbox>
        </CheckboxGroup>
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button variant="outline" color={'primary'} full={true} type="button">
          Cancel
        </Button>
        <Button variant="filled" color={'danger'} full={true} type={'submit'}>
          Delete
        </Button>
      </div>
    </form>
  );
}
