import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Label,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
  TextAreaField,
  TextField,
} from '@uss/react-core';
import { MODEL_TYPE } from 'features/model-listing/constants';
import { CreateModelSchema } from 'features/model-listing/schemas/create-model-schema';
import { ModelType } from 'features/model-listing/types';
import { getWeekFormatTitle } from 'features/model-listing/utility/getWeekFormatTitle';
import { Org, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import React, { Key, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { BsExclamationCircle } from 'react-icons/bs';
export interface CreateModel {
  modelName: string;
  modelDescription: string;
  modelType: ModelType;
  weekBeginIndex?: number;
  plantOrgId?: string;
  locationOrgId?: string;
  totalWeeks: number;
  totalCrewRotations: number;
}

interface CreateModelProps {
  handleClose: () => void;
  defaultValues: CreateModel;
  handleModelCreate: (items: CreateModel) => void;
  isPlantModel: boolean;
}

type Plant = Pick<Org, 'id' | 'plant' | 'weekBeginDayIndex'>;
type PayrollLocation = Pick<Org, 'id' | 'location' | 'locationDescription'>;

export default function CreateModelForm({
  defaultValues,
  handleClose,
  handleModelCreate,
  isPlantModel,
}: CreateModelProps) {
  //selected plant obj from dropdown
  const [plant, setPlant] = useState<Plant | null>(null);
  //selected orgIds from dropdown
  const [plantOrgId, setPlantOrgId] = useState<string | null>(null);
  ;
  const [localtionOrgId, setLocaltionOrgId] = useState<string | null>(null);
  //filtered options array for plant & payroll
  const [plantListArr, setPlantListArr] = useState<Plant[] | []>([]);
  const [locationListArr, setLocationListArr] = useState<PayrollLocation[] | []>([]);

  const [totalCrewRotations, setTotalCrewRotations] = useState<number | null>(
    null
  );
  const [disableButton, setDisableButton] = useState(false);

  const crewRotationItems = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
  ];

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateModel>({
    mode: 'onSubmit',
    resolver: zodResolver(CreateModelSchema),
    defaultValues: defaultValues,
  });

  //watch states
  const modelName = useWatch({ control, name: 'modelName' });
  const modelType = useWatch({ control, name: 'modelType' });
  const plantId = useWatch({ control, name: 'plantOrgId' });
  const locationOrgId = useWatch({ control, name: 'locationOrgId' });
  const totalWeeks = useWatch({ control, name: 'totalWeeks' });
  const totalCrewRotationsVal = useWatch({ control, name: 'totalCrewRotations' });
  const modelDescription = useWatch({ control, name: 'modelDescription' })

  //to disable button if fields are empty
  useEffect(() => {
    if (!modelName || !modelDescription || !totalCrewRotationsVal ||
      !totalWeeks ||
      (modelType === "plant" && (plantId === "" || locationOrgId === ""))) {
      setDisableButton(true)
    } else setDisableButton(false)
  }, [modelName, modelType, plantId, locationOrgId, totalWeeks, totalCrewRotationsVal, modelDescription]);

  //plant selection
  const { data: plantList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
  });
  //location selection
  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId: !plantId ? undefined : plantId,
  });
  //setting weekBeginIndex to 0 as default value
  useEffect(() => {
    setValue('weekBeginIndex', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plantList?.items) {
      const plantArr: Plant[] = [];
      plantList.items.forEach(data => {
        const obj: Plant = { id: data.id, plant: data.plant, weekBeginDayIndex: data.weekBeginDayIndex };
        plantArr.push(obj);
      })
      setPlantListArr(plantArr)
    }
    if (locationList?.items) {
      const locArr: PayrollLocation[] = [];
      const allOption: PayrollLocation = {
        id: 'all',
        location: 'All',
        locationDescription: ''
      };
      locArr.push(allOption)
      locationList.items.forEach(data => {
        const obj: PayrollLocation = { id: data.id, location: data.location, locationDescription: data.locationDescription };
        locArr.push(obj);
      })
      setLocationListArr(locArr)
    }
  }, [plantList, locationList]);

  const handlePlantSelect = (val: Key) => {
    setPlant(plantList?.items.find((e) => e.id === val) ?? null);
    setPlantOrgId(val.toString());
    //updating form states
    setValue('plantOrgId', val.toString());
    //setting payrollLocation to 'All' as default option
    setValue('locationOrgId', 'all');
    setLocaltionOrgId('all');
  };

  const handlePayrollSelect = (val: Key) => {
    setLocaltionOrgId(val.toString());
    setValue('locationOrgId', val.toString());
  };

  const handleCrewSelection = (e: Key) => {
    setTotalCrewRotations(Number(e));
    setValue('totalCrewRotations', Number(e));
  };

  const handleWeekFormat = (e: Key) => {
    setValue('weekBeginIndex', Number(e));
  };

  const handleModelType = (val: Key) => {
    let modelVal: ModelType = 'global';
    if (val === 'plant') modelVal = 'plant';
    setValue('modelType', modelVal);
    //resetting plant and location values on modelType change
    if (val === 'global') {
      setValue('plantOrgId', '');
      setValue('locationOrgId', '');
      setPlantOrgId(null);
      setLocaltionOrgId(null);
    }
  }

  return (
    <form
      className="flex flex-col flex-grow"
      onSubmit={handleSubmit(() => handleModelCreate(getValues()))}
    >
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <Controller
          control={control}
          name="modelName"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Label>
                <p className="text-xs text-black">New Model Name</p>
              </Label>
              <TextField
                type="text"
                label=""
                aria-label="new-model"
                placeholder="Enter new model name"
                errorMessage={errors.modelName?.message}
                onChange={field.onChange}
                maxLength={10}
                isInvalid={invalid}
                name="New Model Name"
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="modelType"
          render={({ field }) => (
            <RadioGroup
              label={'Model Type'}
              value={field.value}
              orientation={'horizontal'}
              onChange={handleModelType}
            >
              <Radio value={'global'} isDisabled={isPlantModel}>
                Global
              </Radio>
              <Radio value={'plant'}>Plant</Radio>
            </RadioGroup>
          )}
        />
        {modelType === MODEL_TYPE.GLOBAL && (
          <Controller
            control={control}
            name="weekBeginIndex"
            render={({ field }) => (
              <RadioGroup
                label={'Week Format'}
                value={field.value ? field.value.toString() : '0'}
                orientation={'horizontal'}
                onChange={handleWeekFormat}
              >
                <Radio value={'0'}>Sun-Sat</Radio>
                <Radio value={'6'}>Sat-Fri</Radio>
              </RadioGroup>
            )}
          />
        )}
        <Controller
          control={control}
          name="plantOrgId"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={plantListArr}
                selectedKey={plantOrgId}
                onSelectionChange={handlePlantSelect}
                label="Plant"
                className="w-full"
                isDisabled={modelType === MODEL_TYPE.GLOBAL}
                isInvalid={invalid &&
                  modelType === MODEL_TYPE.PLANT &&
                  plantOrgId === null}
                errorMessage={errors.plantOrgId?.message}
                placeholder="Select"
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.plant}</SelectOptions>
                )}
              </Select>
              {plant && (
                <div className="flex flex-row mt-2 items-center">
                  <BsExclamationCircle
                    size={'1rem'}
                    className="mr-2 text-3xl"
                  />
                  <p className="text-xs">
                    Week Format: {getWeekFormatTitle(plant.weekBeginDayIndex)}
                  </p>
                </div>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name="locationOrgId"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={locationListArr}
                selectedKey={localtionOrgId}
                onSelectionChange={handlePayrollSelect}
                label="Payroll location"
                isDisabled={
                  locationStatus === 'pending' ||
                  modelType === MODEL_TYPE.GLOBAL ||
                  plantOrgId === null
                }
                className="w-full"
                isInvalid={invalid &&
                  modelType === MODEL_TYPE.PLANT &&
                  localtionOrgId === null}
                errorMessage={errors.locationOrgId?.message}
                placeholder="Select"
              >
                {(item) => (
                  <SelectOptions id={item.id}>
                    {item.location === 'All' ? item.location : item.location &&
                      `${item.location} - ${item.locationDescription ?? ''}`}
                  </SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name="totalWeeks"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Label>
                <p className="text-xs text-black">Number of Weeks</p>
              </Label>
              <TextField
                label=""
                aria-label="weeks"
                type="number"
                placeholder="Enter number of weeks"
                onChange={field.onChange}
                isInvalid={invalid}
                errorMessage={errors.totalWeeks?.message}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="totalCrewRotations"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Select
                items={crewRotationItems}
                selectedKey={totalCrewRotations}
                onSelectionChange={handleCrewSelection}
                label="Number of Crew Rotations"
                isDisabled={false}
                className="w-full"
                isInvalid={invalid && totalCrewRotations === null}
                errorMessage={errors.totalCrewRotations?.message}
                placeholder="Select"
              >
                {(item) => (
                  <SelectOptions id={item.id}>{item.value}</SelectOptions>
                )}
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name="modelDescription"
          render={({ field, fieldState: { invalid, error } }) => (
            <div>
              <Label>
                <p className="text-xs text-black">Model Description</p>
              </Label>
              <TextAreaField
                label=""
                aria-label="model-description"
                placeholder="Enter model description"
                onChange={field.onChange}
                isInvalid={invalid}
                errorMessage={errors.modelDescription?.message}
                rows={6}
              />
            </div>
          )}
        />
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button
          variant="outline"
          color={'primary'}
          full={true}
          type="button"
          onPressChange={handleClose}
        >
          Cancel
        </Button>
        <Button variant="filled" color={'primary'} full={true} type={'submit'}
          isDisabled={disableButton}>
          Create
        </Button>
      </div>
    </form>
  );
}
