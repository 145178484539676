import {
  Button,
  NumberField,
  Radio,
  RadioGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { ModelDetailsResponse, ModelWeeks } from 'features/model-listing/types';
import { getDaysFormat } from 'features/model-listing/utility/getDaysFormat';
import { getEmptyWeekObject } from 'features/model-listing/utility/getEmptyWeekObject';
import { getWeekFormat } from 'features/model-listing/utility/getWeekFormat';
import { useState } from 'react';
interface AddWeeksProps {
  data: ModelDetailsResponse | undefined;
}

export const AddWeeks = ({ data }: AddWeeksProps) => {
  const [value, setValue] = useState();
  const { modelDetail } = useModelDetailStore();
  const weekData: ModelWeeks = getEmptyWeekObject(
    modelDetail?.totalCrewRotations ?? 4
  );
  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className=" overflow-y-auto h-[calc(100vh-14rem)]">
          <div className=" text-xs mb-3">
            Add Weeks
            <RadioGroup value={value} orientation="horizontal">
              <Radio value={'Before'}> Before</Radio>
              <Radio value={'After'}> After</Radio>
            </RadioGroup>
          </div>
          {data && (
            <Select
              items={data.weeks}
              placeholder="Enter week number"
              isDisabled={false}
              className="w-full"
            >
              {(item) => (
                <SelectOptions id={item.weekNo}>{item.weekNo}</SelectOptions>
              )}
            </Select>
          )}
          <div className=" text-xs mt-3 mb-2">Schedule</div>

          {modelDetail && (
            <div className="py-3 border-gray-300 border p-2 rounded-lg">
              <div className="w-full grid gap-4 grid-cols-8 items-center">
                <div className=" text-base text-center"></div>
                {getDaysFormat(modelDetail.weekBeginIndex).map(
                  (day, _index) => (
                    <div
                      key={_index}
                      className="text-center font-semibold text-base justify-center"
                    >
                      {day?.value.charAt(0)}
                    </div>
                  )
                )}

                {weekData.crewRotations.map((crewRotation, index) => (
                  <>
                    <div
                      key={index}
                      className="font-semibold text-base text-center"
                    >
                      {crewRotation.crewRotation}
                    </div>

                    {getWeekFormat({
                      data: [...crewRotation.schedTurns],
                      weekBeginIndex: modelDetail.weekBeginIndex,
                    }).map((schedTurn, _index) => (
                      <div key={_index} className="  text-center text-sm ">
                        <NumberField
                          id={schedTurn?.pkScheduleTurnId}
                          // aria-label={value?.toString()}
                          // value={value === 0 ? NaN : value}
                          // onChange={(e) => handleChange(e)}
                          className="[&_input]:p-0 [&_input]:flex [&_input]:text-center [&_input]:h-7 [&_input]:w-7"
                        />
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
          <Button variant="outline" color={'primary'} full={true} type="button">
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'submit'}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  );
};
